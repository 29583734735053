/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Button, Subtitle } from '@swp/components'
import SiteHeader from '../../components/pl/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Prázdna stránka"}>
        <SiteHeader />

        <Column className="--style2 --left --full" name={"wstęp"} style={{"backgroundColor":"var(--color-blend--95)"}} fullscreen={true}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 el--1 flex--center" anim={"2"} animS={"3"} style={{"maxWidth":1310}} columns={"1"}>
            
            <ColumnWrapper className="pb--0 pl--0 pr--0 pt--0" anim={""} animS={"3"} style={{"maxWidth":""}}>
              
              <Title className="title-box fs--72" style={{"maxWidth":""}} content={"Nazwa strony"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"xgxbgxmeqs"}>
          
          <ColumnWrap className="column__flex el--1" style={{"maxWidth":1310}} columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Title className="title-box" content={"Tytuł"}>
              </Title>

              <Text className="text-box" content={"Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. W razie potrzeby można zmienić kolor bloku tekstu, zmienić wielkość liter, zmienić odstępy między wierszami, wyjustować tekst oraz ustawić maksymalną szerokość tekstu w pikselach."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="bg--bottom --parallax pb--80 pt--80" name={"kontakt"} style={{"backgroundColor":"var(--color-blend--95)"}} parallax={true}>
          
          <ColumnWrap className="column__flex js-anim  --anim7 --anim-s3 --center el--1" anim={"7"} animS={"3"} columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s3" anim={"7"} animS={"3"} style={{"maxWidth":1310}}>
              
              <Title className="title-box" content={"Znajdź równowagę i balans życiowy"}>
              </Title>

              <Button className="btn-box btn-box--shape4" content={"Umów wizytę"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"stopka"} style={{"backgroundColor":"var(--color-supplementary)"}} layout={"l1"}>
          
          <ColumnWrap className="column__flex --left el--2" style={{"maxWidth":1450}} columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"Praktyka psychoterapeutyczna"}>
              </Subtitle>

              <Title className="title-box" content={"<span style='color: var(--color-dominant);'>Mgr. Alicja Wrona</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":375}} content={"Zacznij pisać, to pole jest przeznaczone na twój dowolny tekst."}>
              </Text>

              <Text className="text-box" style={{"maxWidth":375}} content={"Utworzone przez<a style=\"color: inherit\" href=\"https://saywebpage.com\">saywebpage.com</a>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"Kontakt"}>
              </Subtitle>

              <Title className="title-box" content={"<span style='color: var(--color-dominant);'>+48 797 811 2X0</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":""}} content={"Pańska 18, Warszawa<br>info@twoje-stronyy.com"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}